/* //// Home Video Gallery css ///// */


.HomeVideo {
    padding: 80px 0px;
    background:#1a4c90;
}

.HomeVideo h2 {
    line-height: 52px;
}


.HomeVideo img {
    margin: 30px auto;

}

.HomeVideo iframe {
    margin: 30px auto;

}

.HomeVideo .btn_one {
    color: var(--text-color-1);
    margin: 10px 0px;
}

/* Responsive.css */

@media (max-width: 374.98px) {


    .HomeVideo-box-1 {
        margin-left: 10px !important;
    }


}

@media (min-width: 375.98px) and (max-width: 575.98px) {


    .HomeVideo-box-1 {
        margin-left: 10px !important;
    }


}


@media (min-width: 576px) and (max-width: 767.98px) {


    .HomeVideo-box-1 {
        margin-left: 10px !important;
    }


}


@media (min-width: 768px) and (max-width: 991.98px) {


    .HomeVideo-box-1 {
        margin-left: 10px !important;
    }


}


@media (min-width: 992px) and (max-width: 1199.98px) {}


@media (min-width: 1200px) {}