.contact-form {
    background-color: #f7f9fe;
    padding: 45px;
}

.contact-form .section-title .title-header {
    margin-bottom: 52px;
}

.contact-address h3 {
    margin-bottom: 25px;
}

.section-title .title-header:before {
    /* width: 52px;
     bottom: -20px;
    height: 4px;
    left: 0;
    top: auto;
    position: absolute;
    content: '';
    border: 4px solid transparent;
    border-bottom: 0;
    border-top-color: #2d4a8a;*/
}

.contact-form .section-title h5 {
    font-weight: 500;
    text-transform: capitalize;
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 5px;
    color: #2d4a8a;
    margin-top: 25px;
}

.section-title h6.title {
    font-size: 34px;
    line-height: 44px;
    margin-bottom: 0;
}

.clearfix::after {
    display: block;
    clear: both;
    content: "";
}

.contact-form .form-control {
    padding: 17px 24px;
    height: auto;
    border-radius: 0;
    border: 1px solid transparent;
    font-size: 14px;
    background-color: transparent;
}

.contact-form .form-group {
    margin-bottom: 1rem;
}

.contact-form .submit-btn {
    color: #fff;
    border-color: #2d4a8a;
    font-size: 16px;
    padding: 15px 30px;
    display: inline-block;
    line-height: normal;
    border: 1px solid transparent;
    position: relative;
    font-weight: 600;
    overflow: hidden;
    z-index: 2;
    vertical-align: middle;
    text-transform: capitalize;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    background-color: #2d4a8a;
}

.contact-address-icon-box {
    position: relative;
}

.contact-address-icon-box.contact-address-icon {
    vertical-align: top;
    padding-top: 5px;
}

.contact-address-box i {
    font-size: 24px;
}

.contact-address-skincolor {
    background-color: #2d4a8a;
    color: #fff;

}

.contact-address-icon {
    width: 58px;
    height: 58px;
    line-height: 58px;
    float: left;
    margin-right: 20px;
}

.contact-form .rounded-circle {
    border-radius: 50% !important;
}

.contact-address-icon-box.left-icon .contact-address-icon,
.contact-address-icon-box.left-icon .contact-address-content {
    display: table-cell;
    vertical-align: middle;
}

.contact-address-icon i {
    display: inline-block;
    position: relative;
    top: 15%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.separator .sep-line {
    height: 1px;
    border-top: 1px solid #ebebeb;
    display: block;
    position: relative;
    width: 100%;
}