/*  News Css */

.text-white p {
  color: var(--text-color-1);
  margin-bottom: 0px;
}

.bg-news-color {
  background-color: var(--bg-news-color);
  padding: 5px 0px;
}

.blink-text {
  color: var(--bg-news-color);
  font-weight: var(--font-weight);
  font-size: var(--font-size-normal-18px);
  animation: var(--animation);
}

.marquee strong {
  color: var(--bg-news-color);
  font-weight: var(--font-weight);
  font-size: var(--font-size-normal-18px);
  animation: var(--animation);
}

.BlinkingText {
  background-color: var(--bg-blinking-color-10);
  padding: 15px 0px;
  margin: 10px auto;
  border: 4px #000 solid;
}

.BlinkingText h5 {
  color: var(--bg-news-color);
  font-weight: var(--font-weight);
  font-size: var(--font-size-normal-24px);
  animation: var(--animation);
}

.BlinkingText h6 {
  color: var(--bg-news-color);
  font-weight: var(--font-weight);
  font-size: var(--font-size-normal-22px);
  animation: var(--animation);
}

@keyframes blinkingText {
  0% {
    color: var(--bg-blinking-color-1);
    transition: var(--transition);
  }

  25% {
    color: var(--bg-blinking-color-2);
    transition: var(--transition);
  }

  50% {
    color: var(--bg-blinking-color-3);
    transition: var(--transition);
  }

  75% {
    color: var(--bg-blinking-color-4);
    transition: var(--transition);
  }

  100% {
    color: var(--bg-blinking-color-5);
    transition: var(--transition);
  }
}

/* top nav  css */

.top-nav {
  padding-right: 50px;
  background-color: #be1e2d;
  position: relative;
  z-index: 1;
  padding: 10px 0;
}
.top-nav::before {
  content: "";
  height: 100%;
  position: absolute;
  width: 32%;
  background: #f6921e;
  top: 0;
  z-index: -1;
  left: -25px;
  transform: skew(30deg);
}

.top-nav ul {
  margin: 0px;
}

.top-nav ul li {
  margin: 0px 5px;
  float: left;
}

.top-nav span {
  margin: 0px 5px 0px 0px;
}

.top-nav ul li a {
  float: left;
  color: var(--text-color-1);
  margin-right: 5px;
}

.top-nav .fa {
  margin-right: 5px;
}

.text-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/* Responsive.css */

@media only screen and (max-width: 480px) {
  .top-nav ul li a {
    margin-right: 0px;
  }

  .top-nav span {
    margin-right: 0px;
  }
}
