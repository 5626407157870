:root {
  --bg-news-color: #000;
  --bg-black-color: #000;
  --bg-black-color-light: #101010;
  --bg-blinking-color-1: #10c018;
  --bg-blinking-color-2: #ffff;
  --bg-blinking-color-3: #ef0a1a;
  --bg-blinking-color-4: #fff000;
  --bg-blinking-color-5: #04a1d5;
  --bg-blinking-color-6: #f4a636;
  --bg-blinking-color-7: #17384e;
  --bg-blinking-color-8: #3f7fae;
  --bg-blinking-color-9: #d7d7d3;
  --bg-blinking-color-10: #fcc201;
  --bg-blinking-color-11: #444;
  --bg-blinking-color-12: #9f0201;
  --bg-blinking-color-13: #ede7e8;
  --bg-blinking-color-14: #f9f9f9;
  --bg-transparent: transparent;
  --bg-top-nav-color: #cf1415;
  --border-bottom-bg: 1px solid rgb(255 255 255 / 10%);
  --text-color-1: #ffff;
  --text-color-2: #6f6f6f;
  --text-color-3: #363636;
  --text-color-4: #111111;
  --text-color-5: #e9212e;
  --font-weight: bold;
  --font-weight-normal: normal;
  --font-weight-100: 100;
  --font-weight-200: 200;
  --font-weight-300: 300;
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;
  --font-weight-700: 700;
  --font-weight-800: 800;
  --font-weight-900: 900;
  --font-size-normal-14px: 14px;
  --font-size-normal-15px: 15px;
  --font-size-normal-16px: 16px;
  --font-size-normal-17px: 17px;
  --font-size-normal-18px: 18px;
  --font-size-normal-19px: 19px;
  --font-size-normal-20px: 20px;
  --font-size-normal-22px: 22px;
  --font-size-normal-24px: 24px;
  --font-size-heading-1: 24px;
  --font-size-heading-2: 26px;
  --font-size-heading-3: 32px;
  --font-size-heading-4: 42px;
  --font-size-heading-5: 52px;
  --font-size-heading-6: 62px;
  --font-size-heading-7: 72px;
  --animation: blinkingText 2s infinite;
  --transition: 0.4s ease-in-out;
  --font-family-1: "Roboto Condensed", sans-serif;
  --font-family-2: "Jost", sans-serif;
}
