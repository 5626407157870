/* //// Home Services css ///// */

.HomeServicesModel h3 {
  color: #000;
  font-size: 20px;
}
.HomeServices {
  padding: 80px 0px 80px 0px;
  background-color: #f0f5fb;
  position: relative;
}
.HomeServices .Mobile {
  position: relative;
  z-index: 2;
}
.HomeServices .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(189, 30, 45, 0.9);
}
.HomeServices-heading {
  position: relative;
  z-index: 2;
}
.HomeServices-heading h1 {
  margin: 0px 0px 20px 0px;
  color: #fff;
}
.HomeServices-heading p {
  margin: 0px 0px 30px 0px;
  color: #fff;
}

.featured-imagebox {
  margin: 3px 0px;
}
.featured-imagebox,
.featured-imagebox .featured-thumbnail {
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.featured-imagebox .featured-thumbnail img {
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  width: 100%;
  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -ms-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  height: 180px;
}
.featured-imagebox:hover .featured-thumbnail img {
  -moz-transform: scale(1.09);
  -webkit-transform: scale(1.09);
  -ms-transform: scale(1.09);
  transform: scale(1.09);
}
.featured-content span {
  font-size: 52px;
}
.featured-imagebox .featured-content .featured-title h3 {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 10px 0px;
  font-size: 20px;
  color: #f69220;
  font-weight: bold;
}
.prt-box-view-overlay,
.prt-box-view-content-inner {
  position: relative;
  overflow: hidden;
}
.prt-box-view-overlay:before,
.prt-box-view-content-inner:before {
  position: absolute;
  content: "";
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 1;
  opacity: 0;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}
div:hover > .prt-box-view-overlay:before,
div:hover > .prt-box-view-content-inner:before {
  opacity: 0.7;
}
.slick_slider .featured-imagebox {
  margin-bottom: 0;
  margin-top: 0;
}

.bg-base-dark .featured-imagebox .featured-title h3 a,
.bg-base-skin .featured-imagebox .featured-title h3 a {
  color: #fff;
}
.bg-base-dark .featured-imagebox .featured-title h3 a:hover {
  color: var(--base-skin);
}
.bg-base-dark .featured-imagebox .featured-desc p,
.bg-base-skin .featured-imagebox .featured-desc p {
  color: rgba(255, 255, 255, 0.8);
}

/* post */
.featured-imagebox-post {
  overflow: visible;
}
.featured-imagebox-post .featured-content .post-meta span {
  display: inline-block;
  position: relative;
  margin-right: 25px;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 10px;
}
.featured-imagebox-post .featured-content .post-meta span,
.featured-imagebox-post .featured-content .post-meta span a {
  color: #797979;
}
.featured-imagebox-post .featured-content .post-meta span:last-child {
  margin-right: 0;
}
.featured-imagebox-post
  .featured-content
  .post-meta
  span:not(:last-child):after {
  position: absolute;
  display: inline-block;
  content: "";
  background-color: #cacaca;
  width: 1px;
  height: 100%;
  vertical-align: middle;
  top: 0;
  right: -15px;
}
.featured-imagebox-post .featured-content .post-meta span i {
  padding-right: 4px;
  font-size: 14px;
  line-height: 0;
}
.featured-imagebox-post .featured-content .post-meta span i.fa-comments-o {
  font-size: 16px;
}
.featured-imagebox-post .prt-box-post-date .prt-entry-date {
  color: #fff;
  display: inline-block;
  padding: 20px 8px;
  z-index: 2;
  font-size: 15px;
}
.prt-box-post-date .prt-entry-date .entry-month,
.prt-box-post-date .prt-entry-date .entry-year {
  font-size: 16px;
  line-height: 1;
  display: block;
  font-weight: 700;
  text-transform: capitalize;
}
.post-category {
  display: inline-block;
  border-radius: 6px;
  font-size: 14px;
  line-height: 1;
  padding: 6px 10px;
  color: #fff;
  margin-bottom: 15px;
}
.post-bottom.prt-post-link {
  display: flex;
}
.prt-post-format-video {
  position: relative;
  overflow: hidden;
  padding-bottom: 67.5%;
  padding-top: 25px;
  height: 0;
  border-radius: 5px;
}
.prt-post-format-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

/* post-1 */
.featured-imagebox-post.style1 {
  padding: 35px 10px 44px 15px;
  text-align: left;
  margin-left: -80px;
}
.style1 .fa {
  font-size: 52px !important;
}
.featured-imagebox-post.style1 .post-meta span {
  border-bottom: 1px solid #7a8a9e;
  font-size: 13px;
  line-height: 14px;
  font-weight: 500;
  color: #7a8a9e;
  text-transform: uppercase;
}
.featured-imagebox-post.style1 .post-meta {
  position: relative;
  margin-bottom: 10px;
}
.featured-imagebox-post.style1 .post-meta:after {
  position: absolute;
  content: "";
  height: 16px;
  width: 1px;
  left: 45px;
  top: 3px;
  background-color: #7a8a9e;
  margin-top: 3px;
}
.featured-imagebox-post.style1 .post-meta a {
  padding-right: 23px;
}
.featured-imagebox-post.style1 .featured-title h3 {
  font-size: 26px;
  line-height: 37px;
  position: relative;
}

/* featured-imagebox-services.style1*/
.featured-imagebox-services.style1 {
  position: relative;
  padding-bottom: 20px;
  overflow: hidden;
  padding-right: 2px;
  height: 100%;
}
.featured-imagebox-services.style1 .featured-thumbnail img {
  /* transform: translateY(-60px) scale(1.5);*/
  transition: all 1s ease-in;
}
.featured-imagebox-services.style1 .featured-content {
  position: relative;
  padding: 20px 20px 20px;
  /* margin: -35px 40px 14px;*/
  z-index: 2;
  text-align: center;
  background-color: #fff;
  border: 1px solid #dfdfdf;
  height: 290px;
}
.featured-imagebox-services.style1:hover .featured-thumbnail img {
  transform: translateY(0) scale(1.5);
}
.featured-imagebox-services.style1:hover .featured-details-wrap {
  opacity: 0;
  transform: translateY(100%);
  transition: all 0.5s ease;
}
.featured-imagebox-services.style1 .featured-explore-more {
  text-align: center;
}
.featured-imagebox-services.style1 .featured-explore-more a {
  text-transform: capitalize;
  font-weight: 600;
}
.featured-imagebox-services.style1:hover .services-details-box {
  transform: translateY(-91%);
  opacity: 1;
  transition: all 0.5s ease;
}
.featured-imagebox-services.style1:hover .services-details-box h3 a:not(:hover),
.featured-imagebox-services.style1:hover .services-desc {
  color: #fff;
}
.featured-imagebox-services.style1 .services-details-box {
  transform: translateY(-80%);
  opacity: 0;
  transition: all 0.5s ease;
  margin: 0 40px;
}
.featured-imagebox-services.style1 .services-content {
  background-color: #003a66;
  border: none;
  position: static;
  padding: 0;
  text-align: center;
  margin: 0;
  padding: 12px 0px;
  color: #fff;
}
.featured-imagebox-services.style1
  .services-details-box
  .services-content
  .services-desc {
  border-top: 1px solid rgba(255, 255, 255, 0.11);
  padding: 25px 25px 12px 25px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  color: #fff;
  overflow: hidden;
}
.featured-imagebox-services.style1
  .services-details-box
  .services-content
  .services-desc
  p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: rgba(255, 255, 255, 0.8);
}
.featured-imagebox-services.style1
  .services-details-wrap
  .services-explore-more
  a {
  padding: 16px 15px 20px;
  display: block;
  text-align: center;
  font-weight: 600;
  text-transform: capitalize;
  color: #fff;
  background-color: #eb0029;
}

/* Responsive.css */

@media (max-width: 374.98px) {
}

@media (min-width: 375.98px) and (max-width: 575.98px) {
}

@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 1200px) {
}
