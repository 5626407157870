.about_us {
  padding: 90px 0px 90px 0px;
  background: #eff7ff;
}

.img-box2 {
  position: relative;
  z-index: 2;
}
.img-box2 .img1 {
  text-align: right;
}
.img-box2 .shape1 {
  position: absolute;
  bottom: -15px;
  left: -150px;
  z-index: 1;
}
.sub-title {
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
  color: #eb0029;
  font-family: var(--font-family-2);
  text-transform: capitalize;
  margin-bottom: 10px;
  padding-top: 5px;
  position: relative;
}

.sub-title-1 {
  display: inline-block;
  font-size: 40px;
  font-weight: 600;
  color: #eb0029;
  font-family: var(--font-family-2);
  text-transform: capitalize;
  margin-bottom: 10px;
  padding-top: 5px;
  position: relative;
}

.sec-title {
  margin-bottom: 15px;
  font-family: var(--font-family-2);
  font-weight: 600;
  font-size: 24px;
  color: #019588;
}
.about_us .btn_one {
  color: var(--text-color-1);
  margin: 10px 0px;
}

.about_us ul {
  margin: 10px 15px 0px 0px;
  padding: 0px;
}
.about_us ul li {
  list-style-type: none;
}
.about_us ul li::before {
  content: "\f00c";
  margin-right: 10px;
  font-size: 80%;
  background-color: #e02454;
  color: white;
  font-weight: bold;
  padding: 3px 8px;
  border-radius: 3px;
  font-family: "Font Awesome 5 Pro";
}

/* Responsive.css */

@media (max-width: 374.98px) {
}

@media (min-width: 375.98px) and (max-width: 575.98px) {
}

@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
}
