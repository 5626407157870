/* //// Home Gallery css ///// */

.HomeGallery {
  padding: 80px 0px;
  background: #eff7ff;
}

.HomeGallery-heading p {
  color: #000;
  padding: 0px 105px;
  margin: 0px 0px 50px 0px;
}

.HomeGallery-heading h2 {
  line-height: 52px;
  color: #019588;
  font-weight: bold;
  margin: 10px 0px 10px 0px;
}
.HomeGallery-heading h1 {
  line-height: 52px;
  color: #eb0029;
  font-weight: bold;
  margin: 0px 0px 10px 0px;
}
.gallery-title h3 {
  font-size: 1.5rem;
}

.HomeGallery h1 {
  margin: 25px 0px;
}
.HomeGallery p {
  margin: 0px 0px 45px 0px;
}

.gallery-imagebox-services.style2 {
  overflow: hidden;
  margin: 0px 0px 15px 0px;
}
.gallery-imagebox-services.style2 .prt-box-view-overlay:before,
.prt-box-view-content-inner:before {
  content: "";
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgb(0 0 0) 100%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0.8;
  transition-property: top, opacity;
  transition-duration: 0.3s;
}
.gallery-imagebox-services.style2:hover .gallery-thumbnail:before {
  top: 0;
  opacity: 0.5;
}
.gallery-imagebox-services.style2 .gallery-thumbnail:before {
  content: "";
  background: #003a66;
  height: 100%;
  width: 100%;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 1;
  transition: border-radius 0.5s ease-out, top 0.5s ease-out,
    opacity 0.3s ease-out;
}
.gallery-imagebox-services.style2:hover .prt-box-view-overlay img {
  transform: scale(1.2);
}
.gallery-imagebox-services.style2 .prt-box-view-overlay img {
  transform: scale(1.2);
  transition: all 0.35s ease 0s;
}
.gallery-imagebox-services.style2 .gallery-content {
  position: absolute;
  top: auto;
  bottom: 0;
  color: #fff;
  padding: 20px 24px 20px 33px;
  z-index: 9;
}
.gallery-imagebox-services.style2 .gallery-title h3 {
  margin-bottom: 30px;
}
.gallery-imagebox-services.style2 .gallery-title h3 a {
  color: #fff;
}
.gallery-imagebox-services.style2 .gallery-content .gallery-desc p {
  color: rgb(255, 255, 255, 0.8);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* Responsive.css */

@media (max-width: 374.98px) {
}

@media (min-width: 375.98px) and (max-width: 575.98px) {
}

@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
}

@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 1200px) {
}
