.table {
  text-transform: capitalize;
}
.table tr td {
  border: 1px #f1f1f1 solid;
  padding: 5px;
}
.table tr th {
  border: 1px #1f073b solid;
  background-color: #2d0d52;
  color: #fff;
  padding: 8px;
}

.table tr {
  border: 1px solid #d9e4e6;
}
.table tr:nth-child(odd) {
  background-color: #eaf3f3;
}

.HomeOther {
  padding: 80px 0px;
}
.HomeOtherRight {
  padding: 80px 0px;
  background: #be1e2d;
}

.HomeOtherRight ul {
  margin: 10px 20px;
}
.HomeOtherRight ul li {
  list-style-type: square;
  color: #fff;
}
.HomeOtherRight p {
  color: #fff;
}

.HomeOther ul {
  margin: 10px 20px;
}
.HomeOther ul li {
  list-style-type: square;
}

.HomeOther h2 {
  line-height: 52px;
}

.HomeOther h3 {
  line-height: 32px;
  color: #000;
  margin: 0px 0px 15px 0px;
  font-size: 1.5rem;
}

.HomeOther img {
  margin: 30px 0px;
}
.step-img-left {
  padding-bottom: 0;
}
.step-img-right {
  padding-bottom: 0;
}

.HomeOther-box-1 {
  background-color: var(--bg-blinking-color-2);
  /* max-width: 170px; */
  padding: 20px;
  border-radius: 10px;
  position: relative;
  box-shadow: -2px 0px 28px 0px rgb(0 0 0 / 5%);
  border: 1px solid rgb(231, 231, 231);
  margin-top: 30px;
  transition: all 0.3s linear;
}

.HomeOther-box-1 .HomeOther-inner-box {
  background-color: var(--bg-blinking-color-2);
  box-shadow: -2px 0px 15px 0px rgba(0, 0, 0, 0.06);
  height: 56px;
  width: 56px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 14px;
  border-radius: 100%;
  transition: all 0.3s linear;
}

.HomeOther-box-1 h5 {
  font-size: var(--font-size-normal-20px);
  font-weight: 700;
  color: var(--text-color-4);
  margin: 0 0 2px;
  transition: none;
  transition: all 0.3s linear;
}

.HomeOther-box-1 p {
  margin: 10px 0px 25px 0px;
  font-size: var(--font-size-normal-14px);
  line-height: 24px;
  transition: none;
}
