.inner-pages-inner-apply{ padding:70px 0px;}
.CareersBlock{ padding:20px 15px; border-radius:8px; background-color:#f2f2f2; border:2px #eee solid; margin:5px 0px;}

.inner-pages-inner-apply .contact-form{ background:#003a66;}
.CareersBlock h3{ font-size:20px; margin:0px 0px 5px 0px;}
.CareersBlock h4{ font-size:18px; margin:0px 0px 5px 0px;}
.CareersBlock h5{ font-size:16px; margin:0px 0px 5px 0px;}
.CareersBlock h6{ font-size:14px; margin:0px 0px 5px 0px;}

.entry-date .fa-calendar{ margin:0px 5px 0px 0px;}
.entry-date .fa-clock{ margin:0px 5px 0px 0px;}
.entry-date { margin:0px 5px 0px 0px;}


.inner-pages-content ul{ margin:5px 20px;}
.inner-pages-content ul li{ list-style-type:square;}

@media (max-width: 767px) {
.btn_one{ margin:10px 0px}
}