.HomeTeam {
    padding: 80px 0px;
    position: relative;
}

.HomeTeam p {
    margin-bottom: 30px;
}


.HomeTeam-heading p {

    color: #000;
    padding:0px 105px;
    margin:0px 0px 50px 0px;
}


.HomeTeam-heading h2 {
    line-height: 52px;
    color:#019588;
    font-weight:bold;
    margin:10px 0px 10px 0px;
}
.HomeTeam-heading h1 {
    line-height: 52px;
    color:#eb0029;
    font-weight:bold;
   
    
}
.team-imagebox-team.style1 {
	overflow: hidden;
}
.team-imagebox-team.style1 .team-imagebox-inner {
	text-align: center;
	position: relative;
	display: block;
	background-color: #f0f5fb;
	padding: 30px 0px 27px;
	border: 1px solid #ced7e4;
	width: calc(100% - 24px);
	transition: all 0.5s ease;
	z-index: 1;
}

.team-imagebox-team.style1 .team-imagebox-inner::before {
	position: absolute;
	content: '';
	background-color: #003a66;
	width: 100%;
	height: 100%;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	margin: auto;
	transform: scale(0);
	transition: all 0.5s ease;
	z-index: -1;
}

.team-imagebox-team.style1 .team-thumbnail {
	border-radius: 50%;
	width: 150px;
	height: 150px;
	overflow: hidden;
	margin: 0 auto;
	border: 5px solid #fff;
}
.team-imagebox .team-thumbnail img {
	-moz-transform: scale(1);
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	width: 100%;
	-webkit-transition: all .6s ease-in-out;
	-moz-transition: all .6s ease-in-out;
	-ms-transition: all .6s ease-in-out;
	-o-transition: all .6s ease-in-out;
}
.team-imagebox-team.style1 .team-content {
	padding-top: 17px;
}
.team-imagebox-team.style1 .team-imagebox-inner {
	text-align: center;
}
.team-imagebox-team.style1 .team-title h3 {
	margin-bottom: 0;
	transition: all 0.5s ease;
}
.team-imagebox .team-content .team-title h3 {
	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.team-title h3 {
	font-size: 20px;
	line-height: 28px;
	margin-bottom: 15px;
	font-weight: 600;
	text-transform: capitalize;
}
.team-imagebox-team.style1 .team-title span {
	transition: all 0.5s ease;
}
.team-imagebox-team.style1 .prt-media-link {
	background-color: #fff;
	width: 47px;
	text-align: center;
	border-radius: 30px;
	position: absolute;
	z-index: 3;
	right: -23px;
	top: 50%;
	transform: translateY(-50%);
	border: 1px solid #ced7e4;
}
.team-imagebox-team.style1 .prt-link-mail, .team-imagebox-team.style1 .media-block {
	text-align: center;
	display: block;
	padding: 16px 5px 12px;
}
.team-imagebox-team.style1 .prt-link-mail::after {
	position: absolute;
	content: '';
	width: 34px;
	height: 1px;
	left: 5px;
	bottom: 52px;
}
.team-imagebox-team.style1:hover .team-title h3 a ,
.team-imagebox-team.style1:hover .team-title span { color: #fff; transition: all 0.5s ease; }
.team-imagebox-team.style1:hover .team-imagebox-inner:before { transform: scale(1.0); transition: all 0.5s ease; }
.team-imagebox-team.style1:hover .team-thumbnail img { transform: scale(1.0); }
.team-imagebox-team.style1 .prt-link-mail .fa-arrow-right:hover{ color:#eb0029;}
/* Responsive.css */

@media (max-width: 374.98px) {




}

@media (min-width: 375.98px) and (max-width: 575.98px) {


}


@media (min-width: 576px) and (max-width: 767.98px) {



}


@media (min-width: 768px) and (max-width: 991.98px) {

 


}


@media (min-width: 992px) and (max-width: 1199.98px) {}


@media (min-width: 1200px) {}