.logo {
    padding: 2px 0px;
    /* z-index: 10;*/
    float: left;
    position: relative;
    display: block;
}

@media only screen and (max-width: 1199px) {
    .logo {
        padding: 2px 0px;

    }


}