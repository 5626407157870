.inner-pages {
    padding: 100px 0px;
}

.inner-pages p {
    font-size: 16px;
    /*line-height: 42px;*/

}

.inner-pages strong {
    color: #07930b;
}

.inner-pages .fa-angle-double-right {
    margin-right: 8px;

}

.inner-pages .HomeClients {
    background-color: transparent;
    padding: 0px;
    margin: 0px;
}

.inner-pages .HomeBlog {
    background-color: transparent;
    padding: 0px;
    margin: 0px;
}

.inner-pages .HomeTestimonial {
    background-color: transparent;
    padding: 0px;
    margin: 0px;
}

.inner-pages .HomeLatestNews {
    background-color: transparent;
    padding: 0px;
    margin: 0px;
}

.inner-pages .HomeGallery {
    background-color: transparent;
    padding: 0px;
    margin: 0px;
}

.inner-pages .HomeVideo {
    background-color: transparent;
    padding: 0px;
    margin: 0px;
}

/** inner page details css **/

.inner-pages-details {
    padding: 50px 0px;
}

.inner-pages-details h1 {
    margin: 25px 0px;
}



.inner-pages-details h2 {
    margin: 25px 0px 15px 0px;
    color: #282a66;
    font-size: 22px;
}

.inner-pages-details h3 {
    margin: 15px 0px;
    color: #e20808;
    font-size: 20px;
}

.inner-pages-details h4 {
    margin: 15px 0px;
    color: #07930b;
    font-size: 18px;
}

.inner-pages-details h5 {
    margin: 15px 0px;
    color: #6809a5;
    font-size: 18px;
}

.inner-pages-details h6 {
    margin: 15px 0px;
    color: #000;
    font-size: 16px;
    font-weight: bold;
}

.inner-pages-details p {
    margin: 15px 0px;
}

.inner-pages-details .post-meta {
    display: block;
    padding: 15px 0;
    margin: 10px 0;
    border-bottom: 1px solid #ebebeb;
}

.inner-pages-details .post-meta .ttm-meta-line {
    font-size: 13px;
    line-height: 15px;
    display: inline-block;
    padding-right: 25px;
    position: relative;
}

.inner-pages-details .post-meta .ttm-meta-line i {
    padding-right: 7px;
    font-size: 20px;
    color: #2d4a8a;
    vertical-align: middle;
}

.inner-pages-details .post-meta .ttm-meta-line:not(:last-child):after {
    position: absolute;
    content: "";
    height: 100%;
    width: 1px;
    top: 0;
    right: 10px;
    display: block;
    background-color: rgba(0, 0, 0, 0.2);
}

.inner-pages-details .faq h2 {
    margin: 0px;
}

.inner-pages-details .faq {
    margin: 50px 0px;
}


.accordion-button {

    color: #fff;
    background-color: #e81d2b;
    font-size: 28px;
}

.accordion-button:not(.collapsed) {
    color: #0c63e4;
    background-color: #e81d2b;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .125);
}

.accordion-item {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .125);
}

.inner-pages-details ul {
    margin: 15px;
}

.inner-pages-details ul li {
    list-style-type: square;
}