/* //// Home Blog css ///// */

.HomeBlog {
    padding: 80px 0px;
    background-color: #f0f5fb;
}

.HomeBlog h2 {
    line-height: 52px;
}


.HomeBlog-heading p {

    color: #000;
    padding:0px 105px;
    margin:0px 0px 50px 0px;
}
 

.HomeBlog-heading h2 {
    line-height: 52px;
    color:#019588;
    font-weight:bold;
    margin:10px 0px 10px 0px;
}
.HomeBlog-heading h1 {
    line-height: 52px;
    color:#eb0029;
    font-weight:bold;
    margin:0px 0px 20px 0px;
   
    
}


.blog-imagebox-blog.style3 { margin: 0 15px 60px 0; overflow: visible; }
.blog-imagebox-blog.style3 .prt-box-post-date {
   /* height: 75px;
    width: 75px;*/
    padding-top: 16px;
    padding-bottom: 12px;
    z-index: 1;
    color: #003a66;
    text-align: center;
    /*position: absolute;*/
    top: auto;
    bottom: -30px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 9;
    overflow: hidden;
    border-top: 3px solid #e02454;
    background-color: #F0F5FB;
    transition: all 0.5s;
}
.blog-imagebox-blog.style3 .prt-box-post-date span {
    font-weight: 600;
    font-size: 31px;
    line-height: 20px;  
}
.blog-imagebox-blog.style3 .prt-box-post-date label {
    font-weight: 400;
    display: block;
    font-size: 14px;
    margin-top: -8px;
    color: #7a8a9e;
}
.blog-imagebox-blog.style3 .blog-content { padding: 5px 15px 6px 15px; }
.blog-imagebox-blog.style3 .blog-title h3 { margin-bottom: 10px; font-size:22px; }
.blog-imagebox-blog.style3 .blog-title h3 a{   color: #e02454 !important;}
.blog-imagebox-blog.style3 .blog-desc p { 
    margin-bottom: 11px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; 
    font-size:16px;
}
.blog-imagebox-blog.style3 .blog-content .post-meta { margin-bottom: 8px; }
.blog-imagebox-blog.style3 .blog-content .post-meta a { 
    font-size: 13px;
    line-height: 14px;
    font-weight: 500;
    margin-bottom: 13px;
    text-transform: uppercase;
    position: relative;
    color: #7a8a9e; 
}

.blog-imagebox-blog.style3 .blog-content .post-meta a .fa {  margin:0px 10px 0px 0px}
.blog-imagebox-blog.style3 .blog-content .post-meta a:first-child {
    padding-right: 23px;
}
.blog-imagebox-blog.style3 .blog-content .post-meta a:first-child:after {
    position: absolute;
    content: "";
    height: 14px;
    width: 1px;
    right: 12px;
    top: 0;
    background-color: #e7e7e7;
    margin-top: 3px;
}
.blog-imagebox-blog.style3 .blog-content .blog-bottom a { font-weight: 700; color: #e02454; }
.blog-imagebox-blog.style3 .blog-content .blog-bottom a:hover { color: #003a66; }
.blog-imagebox-blog.style3 .prt-box-post-date:hover { background-color: #e02454; border-top: 3px solid #003a66;}
.blog-imagebox-blog.style3 .prt-box-post-date:hover span ,
.blog-imagebox-blog.style3 .prt-box-post-date:hover label { color: #fff; }
.blog-bottom .fa {  margin:0px 0px 0px 10px}
/* Responsive.css */

@media (max-width: 374.98px) {

   


}

@media (min-width: 375.98px) and (max-width: 575.98px) {

   


}


@media (min-width: 576px) and (max-width: 767.98px) {

   


}


@media (min-width: 768px) and (max-width: 991.98px) {

  



}


@media (min-width: 992px) and (max-width: 1199.98px) {}


@media (min-width: 1200px) {}