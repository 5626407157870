.btn-one {
  position: relative;
  z-index: 2;
  overflow: hidden;
  vertical-align: middle;
  display: inline-block;
  border: none;
  text-transform: uppercase;
  text-align: center;
  background-color: #f6921e;
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  line-height: 1;
  padding: 41px 35px 45px 35px;
  -webkit-transition: background-color 0.25s linear, color 0.4s ease-in-out;
  transition: background-color 0.25s linear, color 0.4s ease-in-out;
  width: 100%;
}

.btn-one::before {
  content: "";
  position: absolute;
  height: 100%;
  bottom: 0;
  left: 0;
  width: 0;
  background-color: #000;
  z-index: -1;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  border-radius: inherit;
}

.btn-one:hover,
.btn-one.active {
  color: #fff;
  background-color: #000;
}
.btn-one:hover::before,
.btn-one.active::before {
  width: 100%;
}

.btn-two {
  position: relative;
  z-index: 2;
  overflow: hidden;
  vertical-align: middle;
  display: inline-block;
  border: none;
  text-transform: uppercase;
  text-align: center;
  background-color: #ff9d2d;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  padding: 21px 35px 20px 35px;
  border-radius: 10px;
  -webkit-transition: background-color 0.25s linear, color 0.4s ease-in-out;
  transition: background-color 0.25s linear, color 0.4s ease-in-out;
  margin: 25px 10px 25px 0px;
}

.btn-two::before {
  content: "";
  position: absolute;
  height: 100%;
  bottom: 0;
  left: 0;
  width: 0;
  background-color: #000;
  z-index: -1;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  border-radius: inherit;
}

.btn-two:hover,
.btn-two.active {
  color: #fff;
  background-color: #000;
}
.btn-two:hover::before,
.btn-two.active::before {
  width: 100%;
}

.btn-three {
  position: relative;
  z-index: 2;
  overflow: hidden;
  vertical-align: middle;
  display: inline-block;
  border: none;
  text-transform: uppercase;
  text-align: center;
  background-color: #eb0029;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  padding: 21px 25px 20px 25px;
  border-radius: 10px;
  -webkit-transition: background-color 0.25s linear, color 0.4s ease-in-out;
  transition: background-color 0.25s linear, color 0.4s ease-in-out;
  margin: 0px 10px 0px 0px;
}

.btn-three::before {
  content: "";
  position: absolute;
  height: 100%;
  bottom: 0;
  left: 0;
  width: 0;
  background-color: #000;
  z-index: -1;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  border-radius: inherit;
}

.btn-three:hover,
.btn-three.active {
  color: #fff;
  background-color: #000;
}
.btn-three:hover::before,
.btn-three.active::before {
  width: 100%;
}

.btn-four {
  position: relative;
  z-index: 2;
  overflow: hidden;
  vertical-align: middle;
  display: inline-block;
  border: none;
  text-transform: uppercase;
  text-align: center;
  background-color: #010f1c;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  padding: 21px 25px 20px 25px;
  border-radius: 10px;
  -webkit-transition: background-color 0.25s linear, color 0.4s ease-in-out;
  transition: background-color 0.25s linear, color 0.4s ease-in-out;
  margin: 25px 10px 25px 0px;
}

.btn-four::before {
  content: "";
  position: absolute;
  height: 100%;
  bottom: 0;
  left: 0;
  width: 0;
  background-color: #eb0029;
  z-index: -1;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  border-radius: inherit;
}

.btn-four:hover,
.btn-four.active {
  color: #fff;
  background-color: #eb0029;
}
.btn-four:hover::before,
.btn-four.active::before {
  width: 100%;
}
