/* //// Home Features css ///// */


.HomeFeatures {
    padding: 80px 0px;
    position:relative;
}

.HomeFeatures .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(243,6,41,0.8);
}
.HomeFeatures .Mobile{position:relative; z-index:2;}
.HomeFeatures-heading p {

    color: #fff;
    padding:0px 0px;
    margin:0px 0px 50px 0px;
}

.HomeFeatures-heading{ position:relative; z-index:2;}
.HomeFeatures-heading h2 {
    line-height: 52px;
    color:#fff;
    font-weight:bold;
    margin:10px 0px 10px 0px;
}
.HomeFeatures-heading h1 {
    line-height: 52px;
    color:#eb0029;
    font-weight:bold;
   
    
}

.featured-imagebox-services.style2 {
    overflow: hidden;
}
.featured-imagebox-services.style2 .prt-box-view-overlay:before, .prt-box-view-content-inner:before {
    content: '';
    background: linear-gradient(to bottom,rgba(0,0,0,0) 0%,rgb(0 0 0) 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    opacity: 0.8;
    transition-property: top,opacity;
    transition-duration: .3s;
}
.featured-imagebox-services.style2:hover .featured-thumbnail:before {
    top: 0;
    opacity: 0.5;
}
.featured-imagebox-services.style2 .featured-thumbnail:before {
    content: '';
    background: #003a66;
    height: 100%;
    width: 100%;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 1;
    transition: border-radius .5s ease-out,top .5s ease-out,opacity .3s ease-out;
}   
.featured-imagebox-services.style2:hover .prt-box-view-overlay img {
    transform: scale(1.2);
}
.featured-imagebox-services.style2 .prt-box-view-overlay img {
    transform: scale(1.1);
    transition: all 0.35s ease 0s;
}
.featured-imagebox-services.style2 .featured-content {
    position: absolute;
    top: auto;
    bottom: 0;
    color: #fff;
    padding: 20px 24px 20px 33px;
    z-index: 9;
}
.featured-imagebox-services.style2 .featured-title h3 {
    margin-bottom: 10px;
}
.featured-imagebox-services.style2 .featured-title h3 a {
    color: #fff;
}
.featured-imagebox-services.style2  .featured-content .featured-desc p {
    color: rgb(255, 255, 255, .80);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

/* Responsive.css */


@media (max-width: 374.98px) {



}

@media (min-width: 375.98px) and (max-width: 575.98px) {



}


@media (min-width: 576px) and (max-width: 767.98px) {



}


@media (min-width: 768px) and (max-width: 991.98px) {

   




}


@media (min-width: 992px) and (max-width: 1199.98px) {}


@media (min-width: 1200px) {}